<template>
    <settingLayout>
        <section class="wrap_s_block">
            <h2 class="name_section">Wallet</h2>
            <div class="body_ privacy">
                <h4>Under construction</h4>
            </div>
        </section>
    </settingLayout>
</template>
<script>
import settingLayout from "@/layouts/settingLayout";

export default {
    name: 'wallet',
    components: {
        settingLayout
    },
}
</script>
